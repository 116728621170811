var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-6 col-xl-4"},[_c('a-form',{attrs:{"form":_vm.form},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('strong',[_vm._v("Account Login")])]),_c('div',{staticClass:"card-body"},[_c('a-form-item',{attrs:{"label":"Email Address","colon":false,"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {
                validate: [{
                  trigger: 'change',
                  rules: [{ required: true, message: 'Email address is required.' }],
                }, {
                  trigger: 'blur',
                  rules: [{ type: 'email', message: 'Email address is invalid.' }],
                }]
              }]),expression:"['email', {\n                validate: [{\n                  trigger: 'change',\n                  rules: [{ required: true, message: 'Email address is required.' }],\n                }, {\n                  trigger: 'blur',\n                  rules: [{ type: 'email', message: 'Email address is invalid.' }],\n                }]\n              }]"}],attrs:{"name":"email","type":"email","placeholder":"name@company.com"}})],1),_c('a-form-item',{attrs:{"label":"Password","colon":false,"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {
                validate: [{
                  trigger: 'change',
                  rules: [{ required: true, message: 'Password is required.' }],
                }]
              }]),expression:"['password', {\n                validate: [{\n                  trigger: 'change',\n                  rules: [{ required: true, message: 'Password is required.' }],\n                }]\n              }]"}],attrs:{"name":"password","type":"password"}})],1),_c('router-link',{attrs:{"to":{ name: 'reset' }}},[_vm._v("Recover Password")])],1),_c('div',{staticClass:"card-footer"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(" Login ")]),_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.$router.push({ name: 'register' })}}},[_vm._v(" Create new account ")])],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }