<template>
  <div class="row justify-content-center">
    <div class="col-12 col-md-6 col-xl-4">
      <a-form :form="form" @submit.prevent.native="onSubmit">
        <div class="card">
          <div class="card-header">
            <strong>Account Login</strong>
          </div>
          <div class="card-body">
            <a-form-item label="Email Address" :colon="false" required>
              <a-input
                v-decorator="['email', {
                  validate: [{
                    trigger: 'change',
                    rules: [{ required: true, message: 'Email address is required.' }],
                  }, {
                    trigger: 'blur',
                    rules: [{ type: 'email', message: 'Email address is invalid.' }],
                  }]
                }]"
                name="email"
                type="email"
                placeholder="name@company.com"
              />
            </a-form-item>
            <a-form-item label="Password" :colon="false" required>
              <a-input
                v-decorator="['password', {
                  validate: [{
                    trigger: 'change',
                    rules: [{ required: true, message: 'Password is required.' }],
                  }]
                }]"
                name="password"
                type="password"
              />
            </a-form-item>
            <router-link :to="{ name: 'reset' }">Recover Password</router-link>
          </div>
          <div class="card-footer">
            <a-button type="primary" html-type="submit" :loading="loading">
              Login
            </a-button>
            <a-button type="link" @click="$router.push({ name: 'register' })">
              Create new account
            </a-button>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    }
  },

  created() {
    this.form = this.$form.createForm(this)
  },

  methods: {
    onSubmit() {
      this.form.validateFields(async (err, values) => {
        try {
          if (err) return false
          this.loading = true

          var result = await this.$httpd.post(`/session`, {
            _csrf: this.$store.state.csrfToken,
            ...values,
          })

          this.$store.commit('user', result.data)
          this.$router.push({ name: 'index' })
        } catch (err) {
          if (err.response) {
            this.$message.error(err.response.data.message)
          } else {
            this.$message.error(err.message)
          }

          return false
        } finally {
          this.loading = false
        }
      })
    },
  },
}
</script>
