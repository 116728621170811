<template>
  <div class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-8">
      <div class="card">
        <div class="card-body">
          <BaseSpinner v-if="verified === true" :loading="true">
            <p>We are activating your account and will redirect you shortly.</p>
          </BaseSpinner>
          <div v-else class="text-center">
            <p>The link you clicked appears to be invalid or your account is already verified.</p>
            <a-button type="primary" @click="$router.push({ name: 'index' })">
              Go to Dashboard
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSpinner from '@/components/Base/BaseSpinner'

export default {
  components: {
    BaseSpinner,
  },

  data() {
    return {
      verified: true,
    }
  },

  async mounted() {
    try {
      var { data } = await this.$httpd.put(`/user/email`, {
        token: this.$route.params.id,
      })

      this.$store.commit('user', data)
      window.location.href = '/'
    } catch(err) {
      if (err.response) {
        this.$message.error(err.response.data.message)
      }

      this.verified = false

      return false
    }
  },
}
</script>
